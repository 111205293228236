import {
 ref, computed, onMounted, onUnmounted,
} from 'vue';
import { EventTypeEnum } from '../enums/enums';
import { BreakpointsEnum } from '../enums/breakpoints';

export function useDeviceSize() {
    const windowWidth = ref(window.innerWidth);

    const updateWindowWidth = () => {
        windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
        window.addEventListener(EventTypeEnum.RESIZE, updateWindowWidth);
    });

    onUnmounted(() => {
        window.removeEventListener(EventTypeEnum.RESIZE, updateWindowWidth);
    });

    const isMobile = computed(() => windowWidth.value < BreakpointsEnum.MOBILE_WIDTH_IN_PX);
    const isTablet = computed(() => windowWidth.value >= BreakpointsEnum.MOBILE_WIDTH_IN_PX && windowWidth.value < BreakpointsEnum.TABLET_WIDTH_IN_PX);
    const isLaptop = computed(() => windowWidth.value >= BreakpointsEnum.TABLET_WIDTH_IN_PX && windowWidth.value < BreakpointsEnum.LAPTOP_WIDTH_IN_PX);
    const isDesktop = computed(() => windowWidth.value >= BreakpointsEnum.LAPTOP_WIDTH_IN_PX && windowWidth.value < BreakpointsEnum.DESKTOP_WIDTH_IN_PX);
    const isMobileOrTablet = computed(() => windowWidth.value <= BreakpointsEnum.TABLET_WIDTH_IN_PX);

    return {
        windowWidth,
        isMobile,
        isTablet,
        isLaptop,
        isDesktop,
        isMobileOrTablet,
    };
}
